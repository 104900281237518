#map {
    padding-top: 60px;
    margin-bottom: -120px;
}

.map-container {
    display: flex;
    justify-content: center;
}

.map {
    max-width: 960px;
}
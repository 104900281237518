.gift-area .slick-slide img{
    width: unset;
}
.gift-area{
    padding: 80px 0;
    padding-bottom: 0;
}
.gift-area .section-title p {
    max-width: 640px;
    margin: 0 auto;
}

.gift-item {
    padding: 70px 0;
}
@font-face {
  font-family: "Playlist";
  src: url("./fonts/Playlist Script.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}


.cta-login:hover {
  background-color: red;
}
.couple-item {
    padding-top: 50px;
}

.couple-img {
    overflow: hidden;
    border: 2px solid #003F7D;
    border-radius: 100%;
}

.couple-img:hover.couple-img img {
    transform: scale(1.2);
}

.couple-img img {
    border-radius: 100%;
    transform: scale(1);
    transition: all .3s;
}

.couple-wrap2 {
    background: #fefdf8;
}

.couple-wrap2.couple-s-3 {
    background: #f2fbf9;
}

.couple-wrap2.couple-s-3 .couple-text i {
    color: #003F7D;
}

.couple-content h3 {
    font-size: 21px;
    color:#003F7D;
    font-family: 'Cormorant Garamond', 'Muli', sans-serif;
    text-transform: uppercase;
}

.couple-content2 h4 {
    font-size: 21px;
    color: #9a8c40;
}


.couple-content p {
    padding: 20px 0 30px;
}

.couple-content2 p {
    padding: 1px 20px 9px;
}

.couple-content {
    text-align: center;
}
.couple-text {
    padding: 23px 0;
}

.social-icon ul li a {
    font-size: 24px;
    color: #003F7D;
}

.social-icon ul li {
    padding: 0px 10px;
}

.couple-shape {
    padding-top: 100px;
}

.social-icon ul {
    justify-content: center;
    padding-left: 0;
}

.social-list2 ul li:last-child {
    margin-right: 0;
}
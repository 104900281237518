
.header ul{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
}
.header ul li {
    padding: 25px 15px 30px;
    position: relative;
}
.header {
    background: transparent;
    position: absolute;
    width: 100%;
    z-index: 99;
    background: rgba(255, 255, 255, 0.06);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    width: 100%;
}
.header ul li a{
    color:#fff;
    text-decoration: none;
}
.header ul li a:hover{
    color:#003F7E;
}
.logo {
    margin-top: 10px;
}

.logo h2 a {
    font-size: 36px;
    /* font-family: 'Great Vibes', cursive; */
    font-family: 'Playlist';
    color:#fff;
}

.logo h2 a span {
    color: #fff;
    padding: 0 10px;
}
.header-menu ul li{
    position: relative;
}

.header-menu .submenu {
    position: absolute;
    left:18px;
    top: 140%;
    z-index: 999;
    width: 185px;
    padding: 10px 0px 15px;
    background: #fff;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    visibility: hidden;
}

.header-menu ul>li:hover>.submenu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}
.header-menu .submenu{
    display: unset;
}

.header-menu .submenu li{
    padding: 0px;
}
.header-menu .submenu li a{
    padding-left: 15px;
    display: block;
    color:#444;
    padding: 10px 10px;
    border-bottom: 1px solid #ebebeb;
}
.header-menu .submenu li:last-child a{
    border-bottom: none;
    padding-bottom: 0;
}
.header-menu .submenu li:first-child a{
    padding-top: 0;
}

@media(max-width:1440px){
    .header-menu .submenu2{
        left: -120px;
    }
}